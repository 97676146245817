import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { DateTime } from 'luxon';

const DataFetcher = ({ signal, children }) => {
  const [newData, setNewData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchDataWithRetries();
  }, [signal]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchDataWithRetries();
    }, 30 * 1000);
  
    return () => {
      clearInterval(interval);
    };
  }, [signal]);
  
  const formatDate = (date) => {
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short',
    };
    return new Date(date).toLocaleString('en-US', options);
  };

  
  const fetchDataWithRetries = (maxRetries = 3, retryDelay = 1000) => {
    const attemptFetch = (attempt) => {
      fetch(`https://turbofintech.com:8080/retrieve/${signal}`)
        .then(response => response.json())
        .then(data => {
          const orderData = data.map(item => {

            const dateTimeUTC = DateTime.fromFormat(item.timestamp, "yyyy-MM-dd HH:mm:ss", { zone: "utc" });

            // Convert to Eastern Standard Time (EST)
            const dateTimeEST = dateTimeUTC.setZone("America/New_York");

            // Format the timestamp in EST
            const formattedTimestamp = dateTimeEST.toFormat("yyyy-MM-dd HH:mm:ss");

            return {
              ...item,
              timestamp: formattedTimestamp,
            };
          })
          orderData.sort((a, b) => {
            const timestampA = new Date(a.timestamp);
            const timestampB = new Date(b.timestamp);
            return timestampB.getTime() - timestampA.getTime();
          });
          setNewData(orderData);
          setIsLoading(false);
        })
        .catch(error => {
          console.log(error);
          if (attempt < maxRetries) {
            setTimeout(() => {
              attemptFetch(attempt + 1);
            }, retryDelay);
          } else {
            setIsLoading(false); // Hide loading spinner
          }
        });
    };

    attemptFetch(1);
  };

  if (isLoading) {
    return (
      <div className="loading-container text-center">
        <Spinner animation="border" variant="primary" />
        <div>Loading...</div>
      </div>
    );
  }

  return React.Children.map(children, child =>
    React.cloneElement(child, {
      newData
    })
  );
};

export default DataFetcher;
