import React, { useRef } from 'react';
import { Form } from 'react-bootstrap';
import '../style/input-config.css'
import '../style/table-config.css'
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipboardJS from 'clipboard';



const Table = ({
  columns,
  filteredData,
  rowToggles,
  selectedItems,
  columnFilters,
  toggleRow,
  handleDropdownChange
}) => {
  const cardRef = useRef(null);

  const getColumnHeaders = () => {
    if (columns.length === 0) {
      return null;
    }

    const firstItem = columns[0];
    const headers = Object.keys(firstItem);
    const orderedHeaders = ['symbol', 'direction']; // Specify the desired order of headers
    const filteredHeaders = orderedHeaders.concat(headers.filter(header => !orderedHeaders.includes(header)));
    return filteredHeaders;
  };

  const handleCopyClick = (index) => {
    const cardElement = cardRef.current;

    if (cardElement) {
      let textToCopy = '';

      // Iterate over the keys and values of selectedItem
      let selectedItem = selectedItems[index]
      Object.keys(selectedItem).forEach((key) => {
        textToCopy += `${key}: ${selectedItem[key]}\n`;
      });

      const clipboard = new ClipboardJS(cardElement, {
        text: () => textToCopy.trim()
      });

      clipboard.on('success', () => {
        toast.success('Content copied!', {
          position: toast.POSITION.BOTTOM_RIGHT
        });
        clipboard.destroy();
      });

      clipboard.on('error', () => {
        console.error('Error copying text to clipboard.');
        clipboard.destroy();
      });

      clipboard.onClick({ delegateTarget: cardElement });
    }
  };
  

  return (
    <div className="table-responsive">
      <table className="table color-table">
        <thead>
          <tr>
            <th>#</th>
            {getColumnHeaders()?.map(header => (
            <th key={header}>
              {header}
              <Form.Select
                className='input-config'
                onChange={e => handleDropdownChange(header, e.target.value)}
                value={columnFilters[header] || ''}
              >
                <option value="All">All</option>
                {Array.from(new Set(columns.map(item => item[header]))).map(value => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </Form.Select>
            </th>
          ))}
          </tr>
        </thead>
        <tbody>
        {filteredData.map((item, index) => (
          <React.Fragment key={index}>
            <tr onClick={() => toggleRow(index, item)}>
              <td>{index + 1}</td>
              {getColumnHeaders()?.map(header => {
                const formattedValue =
                  typeof item[header] === 'number'
                    ? item[header].toFixed(3)
                    : item[header];
                return <td key={header}>{formattedValue}</td>;
              })}
            </tr>
            {rowToggles[index] && selectedItems[index] && (
                <tr>
                  <td colSpan={Object.keys(columns[0]).length + 1}>
                    <div className="card shadow mb-6 p-4">
                      <div className="row text-center" ref={cardRef} onClick={() => handleCopyClick(index)}>
                        {Object.keys(selectedItems[index]).map((key, id) => (
                          <div
                            className={`col-4 ${index !== 0 && (index + 1) % 3 === 0 ? '' : 'col-border col-border-color'}`}
                            key={index + key + Math.random() * 1000}
                          >
                            <p className="p-margin">
                              <b>{key}</b>
                            </p>
                            <p className="p-margin">{selectedItems[index][key]}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </td>
                </tr>
              )}
          </React.Fragment>
        ))}
      </tbody>

      </table>
      <ToastContainer />
    </div>
  );
};

export default Table;