// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.color-nav {
    background-color: #012622 !important;
}

.color-letter-nav {
    color: antiquewhite !important;
    border-left: 1px solid antiquewhite !important;
    transition: background-color 0.35s ease-in;
    background-color: #012622 !important;
    border-radius: 5px;
}

.color-letter-nav:hover {
    opacity: 0.8;
    background-color: #003B36;
}

.color-title-nav {
    color: antiquewhite !important;
}`, "",{"version":3,"sources":["webpack://./src/style/nav-config.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;AACxC;;AAEA;IACI,8BAA8B;IAC9B,8CAA8C;IAC9C,0CAA0C;IAC1C,oCAAoC;IACpC,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,yBAAyB;AAC7B;;AAEA;IACI,8BAA8B;AAClC","sourcesContent":[".color-nav {\n    background-color: #012622 !important;\n}\n\n.color-letter-nav {\n    color: antiquewhite !important;\n    border-left: 1px solid antiquewhite !important;\n    transition: background-color 0.35s ease-in;\n    background-color: #012622 !important;\n    border-radius: 5px;\n}\n\n.color-letter-nav:hover {\n    opacity: 0.8;\n    background-color: #003B36;\n}\n\n.color-title-nav {\n    color: antiquewhite !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
