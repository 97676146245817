import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import tacbotz from './assets/tb.png'
import './style/App.css'
import './style/nav-config.css'
import DataFetcher from './components/DataFetch';
import Filter from './components/Filter';
import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {
  return (
    <Router>
      <div>
      <Navbar className="mb-4 color-nav">
        <Navbar.Brand href="#home" className='color-title-nav'><img src={tacbotz} alt="tacbotz" className='image-logo'></img></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="/" className='color-letter-nav'>Entry Signals</Nav.Link>
            <Nav.Link href="/exit" className='color-letter-nav'>Exit Signals</Nav.Link>
            <Nav.Link href="/r2r" className='color-letter-nav'>R2R</Nav.Link>
            <Nav.Link href='/sharks' className='color-letter-nav'>Sharks</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
        <Routes>
          <Route path="/" element={
          <DataFetcher signal="entry">
            <Filter title="Entry Signals" signal="scenario" />
          </DataFetcher>
          } />
          <Route path="/exit" element={
          <DataFetcher signal="fade">
              <Filter title="Exit Signals" signal="fade" />
          </DataFetcher>
          } />
          <Route path="/r2r" element={
          <DataFetcher signal="r2r">
              <Filter title="Risk and Reward" signal="r2r" />
          </DataFetcher>
          } />
          <Route path="/sharks" element={
          <DataFetcher signal="sharks">
              <Filter title="Sharks" signal="sharks" />
          </DataFetcher>
          } />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
