// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p-margin {
    margin: 0;
}

.col-border {
    margin-bottom: 1rem;
}

.col-border-color {
    border-right: 1px solid gray;
}`, "",{"version":3,"sources":["webpack://./src/style/card.css"],"names":[],"mappings":"AAAA;IACI,SAAS;AACb;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,4BAA4B;AAChC","sourcesContent":[".p-margin {\n    margin: 0;\n}\n\n.col-border {\n    margin-bottom: 1rem;\n}\n\n.col-border-color {\n    border-right: 1px solid gray;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
