import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Table from './Table';
import '../style/table-config.css';
import '../style/card.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const Filter = ({ title, newData}) => {
  const [rowToggles, setRowToggles] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [columnFilters, setColumnFilters] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [columns, setColumns] = useState([]);
  const [columnsData, setColumnsData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [data, setData] = useState([]);
  const [indexFiltered, setIndexFiltered] = useState([]);

  


  useEffect(() => {
    const fetchData = () => {
      const modifiedData = newData.map(({ _id, ...rest }) => rest);
      const filterData = newData.map(({ _id, group, timeframe, action, roi_1, roi_2, roi_3, roi_4, time_1, time_2, time_3, time_4,
        leverage, stopLoss, exitSignal, tac0, tac1, tac2,r2rRatio, exitTry, tpsl, ...rest }) => rest);
      setData(modifiedData);
      setColumnsData(filterData);
      setColumns(filterData);
      setFilteredData(filterData);
    }
    fetchData()
  }, [newData]);

  useEffect(() => {
    const filteredByColumnSearch = filterDataByAll(columnsData, columnFilters, searchTerm);
    setFilteredData(filteredByColumnSearch);
  }, [columnFilters, columnsData, searchTerm]);

  const handleSearch = event => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchTerm(searchTerm);
};

  const handleDropdownChange = (field, value) => {
    const newFilters = { ...columnFilters, [field]: value };
    setColumnFilters(newFilters);
  };

  const handleRowClick = (index) => {
    let newSelectedItems = [...selectedItems];
    newSelectedItems[index] = data[indexFiltered[index]];
    setSelectedItems(newSelectedItems);
  };

  const toggleRow = index => {
    let newToggles = [...rowToggles]; //This creates a shallow copy
    newToggles[index] = !newToggles[index]; // It toggles the value at the specified index in the newToggles array. If the value at that index was true, it will become false, and vice versa. This indicates whether the row at that index is expanded or collapsed.
    handleRowClick(index);
    setRowToggles(newToggles); // updates the toggles with the new element and all the previous elements
  };

  const filterDataByAll = (dataToFilter, filters, searchTerm) => {
    const filteredDataIndex = [];
    const dt = dataToFilter.filter((item, index) => {
      const isMatchFilter = Object.keys(filters).every((key) => {
        const fieldValue = item[key];
        const filterValue = filters[key];
        if (typeof fieldValue === "string") {
          return (
            (filterValue === undefined || filterValue === "All" || filterValue === fieldValue)
          );
        } else if (Array.isArray(fieldValue)) {
          return (
            (filterValue === undefined || filterValue === "All" || fieldValue.includes(filterValue))
          );
        }
        return false;
      });
  
      const isMatchSearch = Object.values(item).some((fieldValue) => {
        if (typeof fieldValue === "string") {
          return fieldValue.toLowerCase().includes(searchTerm.toLowerCase());
        } else if (Array.isArray(fieldValue)) {
          return fieldValue.some((val) => val.toLowerCase().includes(searchTerm.toLowerCase()));
        }
        return false;
      });
  
      const isMatch = isMatchFilter && isMatchSearch;
  
      if (isMatch) {
        filteredDataIndex.push(index);
      }
  
      return isMatch;
    });
  
    setIndexFiltered(filteredDataIndex);
    return dt;
  };

  return (
    <Container fluid>
      <h2>{title}</h2>
      <br />
      <input
        className = "input-group-text"
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={handleSearch} // Add onChange event handler
      />
      <br />
      <Table
        data={data}
        columns={columns}
        filteredData={filteredData}
        rowToggles={rowToggles}
        selectedItems={selectedItems}
        columnFilters={columnFilters}
        toggleRow={toggleRow}
        handleDropdownChange={handleDropdownChange}
        handleSearch={handleSearch}
        searchTerm={searchTerm}
        setFilteredData={setFilteredData}
      />
    </Container>
  );
};

export default Filter;