// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.color-table thead tr th{
    background-color: #012622  !important;
    color: antiquewhite;
    transition: background-color 0.35s ease-in;
}

.color-table table {
    align-items: center !important;
}

.color-table tbody tr td{
    background-color: white !important;
    opacity: 0.75;
}

.color-table tbody tr:nth-child(odd){
    background-color: lightgrey;
}

.color-table tbody tr:hover {
    cursor: pointer;
    background-color: #012622;
}

.search-config {
    width: 10%;
}

select option {
    background-color: gray !important; /* Replace with your desired background color */
  }`, "",{"version":3,"sources":["webpack://./src/style/table-config.css"],"names":[],"mappings":"AAAA;IACI,qCAAqC;IACrC,mBAAmB;IACnB,0CAA0C;AAC9C;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,kCAAkC;IAClC,aAAa;AACjB;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,eAAe;IACf,yBAAyB;AAC7B;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,iCAAiC,EAAE,+CAA+C;EACpF","sourcesContent":[".color-table thead tr th{\n    background-color: #012622  !important;\n    color: antiquewhite;\n    transition: background-color 0.35s ease-in;\n}\n\n.color-table table {\n    align-items: center !important;\n}\n\n.color-table tbody tr td{\n    background-color: white !important;\n    opacity: 0.75;\n}\n\n.color-table tbody tr:nth-child(odd){\n    background-color: lightgrey;\n}\n\n.color-table tbody tr:hover {\n    cursor: pointer;\n    background-color: #012622;\n}\n\n.search-config {\n    width: 10%;\n}\n\nselect option {\n    background-color: gray !important; /* Replace with your desired background color */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
